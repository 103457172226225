<template>
    <div class="progress-bar">
        <div class="steps">
            <div v-for="(icon,idx) in icons" :key="idx" class="step-item">
                <img class="icon" :src="getIcon(idx>current-1?icon.off:icon.on)"/>
                <img class="split" v-if="idx<icons.length-1" :src="getIcon(idx>current-2?splits.off:splits.on)"/>
            </div>
        </div>
        <p class="step-title" :class="{'finish':current>=icons.length}">{{title}}</p>
    </div>
</template>

<script>
export default {
    name: "progressBar",
    props: {
        current: {
            type: Number,
            default: 1
        },
        title: {
            type: String,
            default: ''
        },
        icons: {
            type: Array,
            default: () => [
                {
                    on: 'assets/image/icon-1-active.png',
                    off: 'assets/image/icon-1-active.png'
                },
                {
                    off: 'assets/image/icon-2.png',
                    on: 'assets/image/icon-2-active.png'
                },
                {
                    off: 'assets/image/icon-3.png',
                    on: 'assets/image/icon-3-active.png'
                },
                {
                    off: 'assets/image/icon-4.png',
                    on: 'assets/image/icon-4-active.png'
                },
                {
                    off: 'assets/image/icon-5.png',
                    on: 'assets/image/icon-5-active.png'
                }
            ]
        },
        splits: {
            type: Object,
            default: () => ({
                off: 'assets/image/icon-split.png',
                on: 'assets/image/icon-split-active.png'
            })
        }

    },
    methods: {
        getIcon(path) {
            return require('../' + path)
        }
    }
}
</script>


<style scoped lang="stylus" rel="stylesheet/stylus">
@import "../assets/mixins.styl";

.progress-bar {
    width: 100%;
    background #f5fbf6;
    padding 20px 0 0 0

    .step-title {
        text-align: center;
        titleSet()
        color: #009966
        font-size 32px
        line-height 32px
        padding 30px 0
        margin 0
        background #f1f6f2
        &.finish {
            color: #f24b52;
        }
    }

    .steps {
        width: 90%;
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content space-between
        margin 0 auto 20px auto

        .step-item {
            display: flex;
            flex-direction: row;
            align-items center
            justify-content space-around
            flex 1

            &:last-child {
                flex 0
            }

            .icon {
                width 90px;
            }
            .split {
                width: 30px;
            }
        }
    }
}
</style>
