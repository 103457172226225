<template>
    <div class="index">
        <div class="index_inner">
            <div class="index_banner">
                <img class="img" src="../assets/image/banner.jpg" alt="">
            </div>
        </div>
        <div class="index_body">
            <div style="width: 90%;margin: 0 auto">
                <div class="input-container" v-if="progress.current === 1">
                    <div class="readme">
                        <h3>亲爱的会员朋友们：</h3>
                        <p>在辞旧迎新的美好时刻，为丰富各位会员的文化生活，增进家庭互动交流，定于<span class="warning">2025年1月8日（周三）晚19:30</span>举办<span class="warning">“奏响2025！新年音乐会”——SAP北分工会家庭主题活动</span>，邀您携家人朋友共同前往<span class="warning">中央歌剧院</span>，共享一场视听盛宴，存浩然之气，增前行信心！ </p>

                        <h4>活动介绍：</h4>
                        <ul>
                            <li><b>演出时间：</b><span class="warning"> 1月8日（星期三）晚19:30-21:00</span> <i>*演出时间以现场为准</i></li>
                            <li><b>活动地点：</b><span class="warning">中央歌剧院</span><br>
                                <span style="text-decoration: underline #000000">具体地址：北京市朝阳区朝外东中街115号</span>
                            </li>
                            <li><b>参与方式： </b>会员报名成功后，供应商将为会员邮寄纸质票券，会员按照演出时间，持纸质票券自行前往检票入场、观看演出。请遵循剧场工作人员指引，按票券入座，座位先到先得，随机发放，不予指定或调换。
                            </li>
                            <li><b>报名时间：</b>即日起至<span class="warning">1月6日（周一）18:00</span>截止。<br>
                                *报名信息无法修改，我们将尽快为您邮寄票券，您可登入系统查看票券邮寄信息。
                            </li>
                            <li><b>报名范围：</b>截至2025年1月1日前入职，目前仍然在职的SAP北京分公司工会会员。<br>
                                *座位有限，每位会员至高可领2张票券，即可携1名家属同行，如无票前往，将无法入场观看演出。另按剧场要求，1.2米以下儿童谢绝入场，1.2 米以上儿童凭票对号入座；
                            </li>
                            <li><b>演出团队介绍：</b>
                                中央歌剧院作为隶属于文化和旅游部的国家歌剧院，始建于1952年。中央歌剧院交响乐团是中国著名的交响乐团之一，是一支能自如驾御歌剧领域与交响乐世界的全能交响乐团，建团几十年来聚集了一大批国内外颇具实力和影响力的指挥家、演奏家。本场演出力邀乐团首席艺术家，为大家呈现一场精彩纷呈的艺术盛宴。
                            </li>
                            <li><b>更多演出节目内容，请以现场实际情况为准。</b></li>
                        </ul>

                        <h1>重要规则说明及提醒</h1>
                        <ol>
                            <li>
                                本次活动由SAP北京分公司工会举办，所有信息收集仅活动管理员及活动相关公司可见，请准确、如实填写信息；参加活动即视为授权工会对各位会员活动现场照片进行保存、编辑、发布、展示；
                            </li>
                            <li>登记成功后，您将收到管理员发送的邮件确认信息，若未收到登记成功的确认邮件，或登记当中遇到什么问题，请发送邮件至 <a
                                href="mailto:leqiliao@imprforest.com">leqiliao@imprforest.com</a>信箱咨询。
                            </li>
                            <li>一场精彩的演出是由剧院、艺术家、观众共同完成的作品。文明、优雅的观演体验离不开每一位观众的积极配合。为营造安静、优雅的欣赏环境，充分感受艺术的魅力，敬请协助剧场做好以下事项：
                                <ol style="list-style-type: disc">
                                    <li>
                                        请确认演出时间、地点、座位号，一人一票,对号入座。迟到观众请在等候区等候，待幕间(曲间)安排入场，就近入座。
                                    </li>
                                    <li>剧场车位有限，请尽量乘坐公共交通前往剧场。</li>
                                    <li>观众进入剧场需要安检，请预留出时间以免迟到，演出前60分钟可进入剧场。</li>
                                    <li>
                                        请勿将食品、饮料及瓶装水带入剧场。请勿携带大型箱包、照相及摄录器材，如已携带请到衣帽间寄存。请妥善保管贵重物品并接受验票和安检。
                                    </li>
                                    <li>请保持票券完整、清洁。无副券或票券内容被涂改、遮挡，均谢绝入场。</li>
                                    <li>1.2米以下儿童、衣冠不整者、酗酒者谢绝入场。</li>
                                    <li>严禁携带危险物品，剧院内严禁吸烟，禁止使用明火。</li>
                                    <li>演出期间请将手机关闭或调至静音状态，保持剧场安静，请勿随意走动，文明观看演出。</li>
                                    <li>未经允许严禁对演出进行相机(手机）拍照、录音、录像，禁止使用闪光灯。</li>
                                    <li>演出结束后，请有序退场，谢绝逗留。</li>
                                </ol>
                            </li>
                            <li>本次活动的最终解释权归思爱普（中国）有限公司北京分公司工会所有。</li>
                        </ol>
                    </div>
                </div>
                <div class="input-container" v-else-if="progress.current === 2">

                    <div class="input-item" style="margin-top: 10px">
                        <div class="input-item-title">请选择演出票数量</div>

                        <div class="input-item-sub">
                            <div class="select-box">
                                <span class="box-item" :class="{selected: staff.tickets === 1}" @click="staff.tickets=1">1 张</span>
                                <span class="box-item" :class="{selected: staff.tickets === 2}" @click="staff.tickets=2">2 张</span>
                            </div>
                        </div>

                    </div>
                    <div class="input-item">
                        <div class="input-item-title">请填写演出票接收地址</div>
                        <div class="input-item-sub">
                            <input class="input-box" type="text" v-model="staff.recName" placeholder="请输入收件人">
                        </div>

                        <div class="input-item-sub" style="position: relative">
                            <input class="input-box" type="tel" v-model="staff.recTel" placeholder="请输入收件联系电话">
                            <span class="input-validate"
                                  :class="valTelFormat(staff.recTel)?'success':'error'">
          {{ this.staff.recTel.length === 0 ? '*' : (valTelFormat(staff.recTel) ? '√' : '请检查格式') }}</span>
                        </div>

                        <div class="input-item-sub" style="flex-direction: column">
                            <div class="city_wrapper">
                                <area-select type='text' :level='2' v-model="staff.recCity" :data="pcaa"
                                             :placeholders="['北京市', '市辖区', '区域']"></area-select>
                            </div>
                            <input class="input-box" type="text" v-model="staff.recAddr" placeholder="请输入详细地址">
                        </div>
                    </div>
                </div>
                <div class="input-container" v-else-if="progress.current === 3">
                    <div class="input-item" style="margin-top: 10px">
                        <div class="input-item-title">请确认您的报名信息</div>

                        <div class="input-item-sub">
                            <span class="input-item-label">演出票数量</span>
                            <div class="input-item-info">
                                <p class="info">{{ staff.tickets }}张</p>
                            </div>
                        </div>

                        <div class="input-item-sub">
                            <span class="input-item-label">演出票接收地址</span>
                            <div class="input-item-info">
                                <p class="info">{{ staff.recName }}</p>
                                <p class="info">{{ staff.recCity.join(', ') + ', ' + staff.recAddr }}</p>
                                <p class="info">{{ staff.recTel }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="input-container notice">
                        <p style="margin-bottom: 5px;">
                            <span style="font-weight: bolder; font-size: 1.2em">特别提醒：</span>
                        </p>
                        <p>
                            报名成功后，报名信息将无法修改，请您留意关注相关物流信息并妥善保存票券，如票券丢失无法另行出票。
                        </p>
                    </div>
                </div>

                <div class="input-container" v-if="progress.current === 4 || progress.current > 4">
                    <div class="input-item" style="margin-top: 10px">
                        <div class="input-item-title" v-if="progress.current===4">您已报名成功！</div>
                        <div class="input-item-title" v-else>您的报名信息</div>

                        <div class="input-item-sub">
                            <span class="input-item-label">演出票数量</span>
                            <div class="input-item-info">
                                <p class="info">{{ staff.tickets }}张</p>
                            </div>
                        </div>

                        <div class="input-item-sub">
                            <span class="input-item-label">演出票接收地址</span>
                            <div class="input-item-info">
                                <p class="info">{{ staff.recName }}</p>
                                <p class="info">{{ staff.recCity.join(', ') + ', ' + staff.recAddr }}</p>
                                <p class="info">{{ staff.recTel }}</p>
                            </div>
                        </div>

                        <div class="input-item-sub">
                            <span class="input-item-label">演出时间</span>
                            <div class="input-item-info">
                                <p class="info">1月8日（星期三）19:30</p>
                            </div>
                        </div>

                        <div class="input-item-sub">
                            <span class="input-item-label">演出地点</span>
                            <div class="input-item-info">
                                <p class="info">中央歌剧院<br>（北京市朝阳区朝外东中街115号）</p>
                            </div>
                        </div>

                        <div class="input-item-sub">
                            <span class="input-item-label">物流单号</span>
                            <div class="input-item-info">
                                <p class="info">{{staff.tracking_no||'待邮寄'}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="input-container notice" v-if="progress.current !== 5">
                        <p style="margin-bottom: 5px;">
                            <span style="font-weight: bolder; font-size: 1.2em">特别提醒：</span>
                        </p>
                        <p>
                            报名信息无法修改，供应商将于1-3个工作日内为您邮寄纸质票券，座位先到先得，随机发放，凭票入场，对号入座，不予指定或调换。
                            请您关注物流信息并妥善保管票券，票券遗失将无法补票。另按剧场要求，1.2米以下儿童谢绝入场，同时提醒您珍惜票券资源，按时前往剧场观看演出。<br>
                            如有系统报名或活动相关问题，请发送邮件至<a href="mailto:leqiliao@imprforest.com">leqiliao@imprforest.com</a>。
                        </p>
                    </div>
                </div>


                <div class="input-container notice" v-if="progress.current === 5">
                    <p style="margin-bottom: 5px;">
                        <span style="font-weight: bolder; font-size: 1.2em">特别提醒：</span>
                    </p>
                    <p>
                        报名信息无法修改，供应商将于1-3个工作日内为您邮寄纸质票券，座位先到先得，随机发放，凭票入场，对号入座，不予指定或调换。
                        请您关注物流信息并妥善保管票券，票券遗失将无法补票。另按剧场要求，1.2米以下儿童谢绝入场，同时提醒您珍惜票券资源，按时前往剧场观看演出。<br>
                        如有系统报名或活动相关问题，请发送邮件至<a href="mailto:leqiliao@imprforest.com">leqiliao@imprforest.com</a>。
                    </p>
                </div>
            </div>
            <!--
            <div class="body-actions" v-if="!staff.status||staff.status <2||datePickRange.begin.getTime()>Date.now()">
                      -->
            <div class="body-actions" v-if="!staff.status||staff.status <2">
                <button class="btn-prev" :disabled="btnPrevDisabled" @click="btnPrevHandle" v-if="progress.current > 1 && progress.current < 4">
                    {{ progress.current < 3 ? '上一步' : (progress.current === 5 ? '已确认' : '返回修改') }}
                </button>
                <button class="btn-next" :disabled="btnNextDisabled" @click="btnNextHandle" v-if="progress.current && progress.current < 4">
                    {{ progress.current < 3 ? (progress.current === 1 ? '我已知晓' : '确认，下一步') : '确认提交' }}
                </button>
            </div>

            <Error v-if="toggleDialog" @close="validateTel">
                <div class="input-container">
                    <div class="input-item">
                        <div class="input-item-title" style="width: 100%;text-align: center">再次输入门票兑换码接收手机号</div>
                        <div class="input-item-sub" style="position: relative">
                            <input class="input-box" type="tel" v-model="retypeTel" placeholder="门票兑换码接收手机号"/>
                            <span class="input-validate" style="z-index: 99"
                                  :class="valTelFormat(retypeTel)?'success':'error'">
          {{ retypeTel.length === 0 ? '*' : (valTelFormat(retypeTel) ? '√' : '请检查格式') }}</span>
                        </div>
                        <div class="input-item-title">
                            <p class="title-tips" style="color: #000; line-height: 1.2em">
                                票券将直接发送至该手机号码，请您仔细核对后提交，避免错误输入丢失票券。</p>
                        </div>
                    </div>
                </div>
            </Error>

            <Error v-if="toggleAlert" @close="toggleAlert=false" :message="alertMsg"></Error>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import axios from "axios";
import ProgressBar from "@/components/progressbar.vue";
import pcaa from "area-data/pcaa";
import {valTel} from "@/pages/valid";
import Error from '../components/error'

export default {
    name: "login",
    components: {
        ProgressBar,
        Error,
    },
    data() {
        return {
            pcaa: pcaa,
            progress: {
                current: 1,
                title: [
                    '活动须知',
                    '请填写您的出行信息',
                    '请填写接收方式',
                    '请仔细核对并确认您的报名信息',
                    '您已成功报名！'
                ]
            },
            toggleCalendar: false,
            toggleDialog: false,
            toggleAlert: false,
            alertMsg: '',
            submitting: false,
            retypeTel: '',

            showedAddChild: false,
            showedAddCar: false,
        }
    },
    computed: {
        ...mapState({
            staff: state => state.staff
        }),
        btnNextDisabled: function () {
            if (this.progress.current === 1) {
                return false;
            } else if (this.progress.current === 2) {
                return !valTel(this.staff.recTel) ||
                    this.staff.tickets<=0 ||
                        this.staff.recName.length === 0 ||
                        this.staff.recCity.length < 3 ||
                        this.staff.recCity[2] === '区域' ||
                        this.staff.recAddr.length === 0
            }else if (this.progress.current === 3){
                return false;
            }
            else if (this.progress.current === 4) {
                return this.submitting;
            } else if (this.progress.current === 5) {
                return false;
            }

            return true;
        },
        btnPrevDisabled: function () {
            return !(this.progress.current > 1) && this.submitting;
        },
    },
    mounted() {
        if (this.staff.id.length < 5) {
            this.$router.replace({name: 'login'})
        }

        if (this.staff.status >= 1) {
            this.progress.current = 5;
        }
    },
    methods: {
        ...mapActions({

        }),
        btnPrevHandle() {
            this.progress.current > 1 && this.progress.current--;
        },
        btnNextHandle() {
            if (this.progress.current < 3) {
                this.progress.current++
            } else if (this.progress.current > 4) {
                this.progress.current = 1;
            } else {
                this.submitting = true;
                axios.post('/submit', {staff: this.staff}).then(rsp => {
                    this.submitting = false;
                    this.progress.current = 4;
                    //alert(rsp.data.message);
                    this.alertMsg = rsp.data.message;
                    this.toggleAlert = true;
                }).catch((e) => {
                    alert(e.response.data.message)
                    // this.isError = true
                    // this.message = e.response.data.message
                    this.submitting = false;
                })
            }
        },
        btnTickets(action) {
            console.log(action);
            if (action === 'add-adult') {
                if ((this.staff.tickets.child + this.staff.tickets.adult) < this.staff.tickets.limit) {
                    this.staff.tickets.adult++;
                }
            } else if (action === 'minus-adult') {
                this.staff.tickets.adult = this.staff.tickets.adult <= 1 ? 1 : this.staff.tickets.adult - 1;
            } else if (action === 'add-child') {
                if ((this.staff.tickets.child + this.staff.tickets.adult) < this.staff.tickets.limit) {
                    this.staff.tickets.child++;
                }

                if (!this.showedAddChild) {
                    this.alertMsg = '每张成人票可携1名身高不超过1米的儿童免费入园，如随行家属满足此条件，则无需在系统中勾选“儿童票”选项。';
                    this.toggleAlert = true;
                    this.showedAddChild = true;
                }
            } else if (action === 'minus-child') {
                this.staff.tickets.child = this.staff.tickets.child <= 1 ? 0 : this.staff.tickets.child - 1;
            } else if (action === 'add-car') {
                if (this.staff.tickets.car < this.staff.tickets.car_limit) {
                    this.staff.tickets.car++;
                }

                if (!this.showedAddCar) {
                    this.alertMsg = '步行游览门票中包含的小火车游览区与自驾车游览区为重叠区域，如需自驾车游览，则勾选此项。'
                    this.toggleAlert = true;
                    this.showedAddCar = true;
                }
            } else if (action === 'minus-car') {
                this.staff.tickets.car = this.staff.tickets.car <= 1 ? 0 : this.staff.tickets.car - 1;
            }
        },
        valTelFormat(input) {
            return valTel(input);
        },
        validateTel() {
            if (valTel(this.retypeTel)) {
                if (this.retypeTel === this.staff.tel) {
                    this.toggleDialog = false;
                    this.progress.current++
                    return true;
                }
            }
            this.toggleDialog = false;
            this.alertMsg = '输入的兑换码接收手机号码不一致，<br>请重新输入';
            this.toggleAlert = true;
        },
        onCalendarClick(evt) {
            //console.log(evt);

            if (evt instanceof Event) {
                this.toggleCalendar = true
            } else {
                this.toggleCalendar = false
                if (evt.event && evt.event instanceof Event)
                    evt.event.stopPropagation();
            }
        }
    },
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
@import '../assets/mixins.styl'

.index
    width 750px
    min-height 100vh
    position relative
    margin 0 auto
    background #f1f6f2
    padding-bottom 150px

    .index_inner
        width 750px
        height 100%

        .index_banner
            width 100%

            .img
                width 100%;

    //box-shadow 10px 10px 15px rgba(0, 0, 0, 0.2);

    .index_body
        width 750px
        //height 80vh
        //position absolute
        //top 300px
        display flex
        flex-direction column
        align-items center

        .color-notice
            color #f24b52

        .input-container
            .input-calendar
                width: calc(750px * 0.8);
                z-index: 999;
                position: absolute;
                right: 0;
                top: 50px;
                display: flex;
                flex-direction: row;
                justify-content: center

            .input-item
                margin-bottom 20px

            .input-box
                width 100%
                border none
                font-size 32px
                border-bottom solid 1px #a1a4ad
                margin-bottom 10px

                &:focus
                    outline none

            .input-validate
                position absolute
                top 0
                right 0
                line-height 24px

                &.success
                    color #009966

                &.error
                    color #f24b52

            .city_wrapper
                width 100%
                display flex
                flex-direction column
                margin-bottom 10px;
                font-size 1.2em

                .city_wrapper_title
                    display block
                    font-size 32px
                    padding-bottom 20px

            &.notice
                p
                    margin 0
                    font-size 24px
                    color #f24b52

        .input-item
            width 100%
            background #ffffff
            border-radius 15px
            display flex
            flex-direction row
            flex-wrap wrap
            justify-content space-between
            fontFamily()
            font-size 26px
            padding 20px 20px
            box-sizing border-box

            .input-item-label
                color #666666

            .input-item-title
                color $theme
                font-size 32px

                .title-tips
                    color #666666
                    font-size 24px
                    padding-left 10px

                p.title-tips
                    margin 0

                p.tips
                    font-size 24px
                    margin 5px 0 0 0

            .input-item-info
                flex 1
                display flex
                flex-direction column
                justify-content right

                p
                    margin 0
                    text-align right
                    font-size 28px
                    line-height 36px

            .input-item-inner
                flex 1
                display flex
                justify-content right

                input
                    min-width 16em
                    height 100%
                    text-align right
                    border none
                    font-weight bold

                    &::placeholder
                        font-size 24px
                        color #000

                input[type="checkbox"]
                    width 2em
                    min-width auto

                //accent-color white


                &.number
                    align-items center
                    color #f24b52
                    font-weight bold
                    font-size 1.5em

            .input-item-sub
                width 100%
                display flex
                flex-direction row
                margin-top 20px

                .subitem-title
                    flex 1

                    p
                        margin 0

                    .title
                        font-size 28px

                        .title-tips
                            color #f24b52
                            font-size 24px

                    .tips
                        color #666666
                        font-size 24px
                        padding-left 10px
            .select-box
                width 100%
                display inline-flex
                flex-direction row
                justify-content space-around

                .box-item
                    width 8em
                    height 2.5em;
                    margin 0 10px
                    line-height 2.5em
                    vertical-align middle
                    font-size 32px !important

                    border solid 1px #d3d3d3
                    border-radius 10px
                    background $bcGray
                    text-align center

                    &.selected
                        background #edc9a5


            .input-num-btn
                width 150px
                display inline-flex
                align-items center
                justify-content right

                .label-num
                    width 1em
                    display inline-block
                    margin 0 10px
                    font-size 36px
                    font-weight bold
                    text-align center
                    color #333333

                .btn-add,
                .btn-minus
                    width 36px
                    height 36px
                    line-height 32px
                    font-size 36px
                    font-weight bold
                    display inline-block
                    border solid 1px #666666
                    border-radius 50%
                    color #666666
                    text-align center
                    cursor pointer

                    &.disabled
                        background #dddddd

        .body-actions
            width 100%
            display flex
            margin-top 20px
            margin-bottom 50px
            flex-direction row
            justify-content space-around

            button
                font-size 36px !important

            .btn-prev
                buttonSet()
                width 300px
                background #a6b4a8

                &:disabled
                    background $deepGray

            .btn-next
                buttonSet()
                width 300px

    .input-container
        .readme
            font-size 24px
            fontFamily()

            .warning
                font-weight bolder
                color $theme

            .notice
                background yellow

            p
                margin 5px 0;

            b
                font-weight bolder

            h1, h3, h4, ul, ol
                margin 0
                padding 0

            h1
                text-align center
                color $theme
                font-size 32px

            h3
                font-size 28px
                margin-top 14px;

            h4
                color #ffffff
                background #DE8344
                font-weight bold
                padding 10px 0 10px 10px
                margin 10px 0
                font-size 26px

            ul, ol
                margin-left 1em

                li
                    padding-bottom 10px

            ul
                //list-style-type '\00A7\0020'
                list-style-type decimal
                list-style-position outside

            ol
                list-style-type '\00BB\0020'
                list-style-position outside

::v-deep .vc-container {
    width 80%;
    --rounded: 15px !important
    --rounded-lg: 15px !important
}

::v-deep area-cascader-wrap {
    display inline-block
}

::v-deep .area-select {
    position: relative;
    display: inline-block;
    vertical-align: top;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 50px;
    width: 100%;
    cursor: pointer;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #e1e2e6;
    margin 0
}

::v-deep .area-select-wrap .area-select {
    margin 0 0 20px
}

::v-deep .area-select .area-selected-trigger {
    position: relative;
    display: block;
    font-size: 32px;
    cursor: pointer;
    margin: 0;
    line-height 50px;
    overflow: hidden;
    white-space: nowrap;
    //text-overflow: ellipsis;
    height: 100%;
    padding: 0 0 0 44px
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius: 10px;
}

::v-deep .area-selectable-list-wrap {
    max-height 500px
}

::v-deep .area-select .area-select-icon {
    border 10px solid transparent
    border-top-color: #a1a4ad;
    position: absolute;
    top: 50%;
    //margin-top: -0.026667rem;
    right: 40px;
}

::v-deep .area-selectable-list {
    font-size: 40px !important;
}

::v-deep .area-selectable-list .area-select-option {
    padding 0 0 0 44px
    font-size 30px
    height 68px
    line-height 68px
}

::v-deep .area-selectable-list .area-select-option.selected {
    background-color: #e4e8f1;
    color $theme
    font-weight: 700
}

::v-deep .cascader-menu-list .cascader-menu-option.selected {
    background-color: #e4e8f1;
    color $theme
    font-weight: 700
}

//::v-deep .cascader-menu-list .cascader-menu-option {
//  position: relative;
//  white-space: nowrap;
//  overflow: hidden;
//  text-overflow: ellipsis;
//  cursor: pointer;
//  padding: 0 15px 0 20px;
//  height: 50px;
//  line-height: 50px
//}
::v-deep .cascader-menu-list {
    position: relative;
    margin: 0;
    font-size: 26px;
    color: #565656;
    padding: 6px 0;
    list-style: none;
    display: inline-block;
    height: 430px;
    overflow-x: hidden;
    overflow-y: auto;
    min-width: 150px;
    vertical-align: top;
    background-color: #fff;
    border-right: 1px solid #e4e7ed
}
</style>
