<template>
    <div class="score">
        <div class="index_inner">
            <div class="index_banner">
                <img class="img" src="../assets/image/banner.jpg" alt="">
            </div>
            <router-view/>
        </div>
        <div v-if="!this.staff.recAddr" style="display: flex; flex-direction: column;">
            <div class="score_num">
                <p class="score_num_title">恭喜您，您已完成答题，得分：</p>
                <div class="score_num_wrapper">
                    <span class="number">{{ this.score * 10 }}</span>
                    <span>分</span>
                </div>
            </div>

            <div class="score_info">
                <div class="score_gift">
                    <p>您将获得本次奖品：</p>

                    <div class="index_body_img">
                            <img src="../assets/image/gift_1.png" alt="">
                               <span>日用清洁产品组合</span>
                    </div>
                </div>
                <p style="text-align: right">*图片仅供示意参考，具体以实物为准。</p>
            </div>

            <div class="staff_info_box">
                <button class="box_button online" @click="staffInfo()">填写收件信息</button>
            </div>
        </div>
        <div v-else>
            <div class="score_info">
                <div class="score_gift">
                    <div class="alert_img" :class="{active:!isCorrect}">
                        <img src="../assets/image/icon_correct.png" alt="">
                    </div>
                    <ul class="score_gift_detail">
                        <p class="score_gift_title">恭喜您完成答题并成功提交问卷信息，
                            <br>我们将按照您提交的信息为您邮寄礼品：</p>
                        <li><span>姓名:</span>
                            <p>{{ this.staff.recName }}</p></li>
                        <li><span>电话:</span>
                            <p>{{ this.staff.recTel }}</p></li>
                        <li><span>地址:</span>
                            <p>{{ this.staff.recCity[0] }}
                                {{ this.staff.recCity[1] == '市辖区' ? '' : this.staff.recCity[1] }}
                                {{ this.staff.recCity[2] }}
                                {{ this.staff.recAddr }}</p></li>
                                  <!--<li><span>礼品:</span><p>{{ this.staff.recGift }}</p></li>-->
                    </ul>
                </div>
                <div class="info_warn">
                    <p>如有问题请联系：<a href="mailto:shengming_service@126.com">shengming_service@126.com</a></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
    name: "score",
    data() {
        return {
            score: ''
        }
    },
    mounted() {
        this.score = this.getScore()
        // console.log(this.getScore());
        // console.log(this.getResult());
    },
    computed: {
        ...mapState({
            staff: state => state.staff
        })
    },
    methods: {
        ...mapGetters({
            getScore: 'getScore',
            getResult: 'getResult'
        }),
        staffInfo() {
            this.$router.push({path: 'staff'})
        }
    },

}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
@import '../assets/mixins.styl'
ul, li
    list-style-type none
    padding 0
    margin 0

.score
    display flex
    flex-direction column
    align-items center
    //justify-content center
    height calc(100vh - 50vw)
    position relative

    .staff_info_box
        //width 100%
        padding 31px 75px

        .box_button
            buttonSet()

    .score_num
        //margin-top 240px
        padding 10px 0

        .score_num_title
            text-align center
            font-size 32px

        .score_num_wrapper
            text-align center

            .number
                display inline-block
                text-align center
                font-family hxb
                color $theme
                font-size 200px
                margin 0

    .score_info
        //position absolute
        //bottom 40px

        .score_gift
            //background-color white
            padding 0 38px 50px
            //height 400px
            text-align center
            font-size 28px

            .alert_img
                width 150px
                height 150px
                border-radius 50%
                background-color green
                margin 5vh auto 0 auto

                img
                    width 100%
                    height 100%

            p
                margin 0;

            .index_body_img
                width 100%
                display flex
                flex-direction column
                align-items center

                img
                    width 360px
                    padding 10px 0

                span
                    padding 10px 0

            .score_gift_detail
                //height 180px
                display flex
                flex-direction column
                justify-content space-around

                .score_gift_title
                    padding 40px 0
                    text-align center
                    font-size 32px

                li
                    display flex
                    flex-direction row
                    padding-left 37px
                    font-family "source han sans cn"

                    span
                        width 3.2em
                        text-align: justify;
                        text-align-last: justify;
                        padding-right 28px
                        line-height 48px
                        font-size 30px

                    p
                        text-align left
                        margin 0
                        width 500px
                        font-size 30px
                        line-height 48px
                        word-wrap break-word
                        color #333333

        .info_warn
            border-top 2px solid #CCCCCC
            padding-top 20px

            p
                font-size 28px
                font-family "source han sans cn"
                color #999
                line-height 48px
                font-weight 500
                text-align center


.index_inner {
    width: 750px;
    //height: 100%;
}

.index_img_box {
    position: absolute;
    top: 0;
    width: 750px;
    padding: 40px 0 0 40px;
    box-sizing: border-box;
}

.index_logo {
    width: 88px;
    height: 122px;
}

.index_banner {
    width: 100%
}

.index_banner > .img {
    width: 100%;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.2);
}

.copyright
    width 100%
    color $gray
    margin-top 20px
    text-align center
</style>
